<template>
  <a-row :gutter="[32, 16]">
    <a-col :span="12">
      <div class="d-flex flex-column flex-grow-1 mb-3">
        <span class="font-weight-bold mb-1">Date</span>
        <a-range-picker
          v-model="datetime"
          format="DD-MM-YYYY"
          :allowClear="false"
        />
      </div>
      <div class="d-flex flex-column flex-grow-1 mb-3">
        <span class="font-weight-bold mb-1">Order Agents</span>
        <a-checkbox
          class="mb-2"
          v-model="isAllOrderAgents"
          @change="changeAllOrderAgentsHandler"
          :indeterminate="isAllIndeterminateOrderAgents"
        >
          <span class="mr-1">All</span>
        </a-checkbox>
        <div class="d-flex align-items-center mb-2">
          <a-checkbox
            class="mr-2"
            v-model="useOrderAgentsSelected.regularAgents.value"
            @change="changeUseAgentsSelected($event, 'regularAgents')"
            :disabled="useOrderAgentsSelected.regularAgents.disabled"
          />
          <custom-multiselect
            v-model="selectedOrderAgents.regularAgents"
            :options="regularAgents"
            :placeholder="!useOrderAgentsSelected.regularAgents.disabled ? 'Regular' : 'Regular (No agents)'"
            :disabled="!useOrderAgentsSelected.regularAgents.value || useOrderAgentsSelected.regularAgents.disabled"
            active-label="Regular"
          />
        </div>
        <div class="d-flex align-items-center mb-2">
          <a-checkbox
            class="mr-2"
            v-model="useOrderAgentsSelected.externalAgents.value"
            @change="changeUseAgentsSelected($event, 'externalAgents')"
            :disabled="useOrderAgentsSelected.externalAgents.disabled"
          />
          <custom-multiselect
            v-model="selectedOrderAgents.externalAgents"
            :options="externalAgents"
            :placeholder="!useOrderAgentsSelected.externalAgents.disabled ? 'External' : 'External (No agents)'"
            :disabled="!useOrderAgentsSelected.externalAgents.value || useOrderAgentsSelected.externalAgents.disabled"
            active-label="External"
          />
        </div>
        <div class="d-flex align-items-center mb-2">
          <a-checkbox
            class="mr-2"
            v-model="useOrderAgentsSelected.systemAgents.value"
            @change="changeUseAgentsSelected($event, 'systemAgents')"
            :disabled="useOrderAgentsSelected.systemAgents.disabled"
          />
          <custom-multiselect
            v-model="selectedOrderAgents.systemAgents"
            :options="systemAgents"
            :placeholder="!useOrderAgentsSelected.systemAgents.disabled ? 'System' : 'System (No agents)'"
            :disabled="!useOrderAgentsSelected.systemAgents.value || useOrderAgentsSelected.systemAgents.disabled"
            active-label="System"
          />
        </div>
        <div class="d-flex align-items-center">
          <a-checkbox
            class="mr-2"
            v-model="useOrderAgentsSelected.noAgentsFound.value"
            @change="changeUseAgentsSelected($event, 'noAgentsFound')"
            :disabled="useOrderAgentsSelected.noAgentsFound.disabled"
          />
          <custom-multiselect
            v-model="selectedOrderAgents.noAgentsFound"
            :options="noAgentsFound"
            :placeholder="!useOrderAgentsSelected.noAgentsFound.disabled ? 'No agent found' : 'No agent found (No agents)'"
            :disabled="!useOrderAgentsSelected.noAgentsFound.value || useOrderAgentsSelected.noAgentsFound.disabled"
            active-label="No agent found"
          />
        </div>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <span class="font-weight-bold mb-1">Amount</span>
        <div>
          <a-radio-group
            v-model="selectedCurrencyId"
            class="d-flex flex-column"
          >
            <div class="d-flex mb-1">
              <a-radio v-for="currency in amountCurrencies" :value="currency.id" :key="currency.id">
                <span class="mr-1">{{ currency.name }}</span>
              </a-radio>
            </div>
            <div class="d-flex">
              <a-input-number
                v-model="amountFrom"
                class="w-100 mr-2"
                :default-value="0"
                :step="0.01"
                :min="0"
                :parser="customParser"
                :placeholder="`From ${selectedCurrencyName}`"
              />
              <a-input-number
                v-model="amountTo"
                class="w-100"
                :default-value="0"
                :step="0.01"
                :min="0"
                :parser="customParser"
                :placeholder="`To ${selectedCurrencyName}`"
              />
            </div>
          </a-radio-group>
        </div>
      </div>
    </a-col>
    <a-col :span="12">
      <div v-if="!hideProjectList" class="d-flex flex-column flex-grow-1 mb-3">
        <span class="font-weight-bold mb-1">Projects</span>
        <custom-multiselect
          v-model="selectedProjects"
          :options="projectList"
          placeholder="Select project"
          active-label="Projects"
        />
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <span class="font-weight-bold mb-1">Order Status</span>
        <div class="mb-1">
          <a-checkbox
            v-model="isAllOrderStatus"
            @change="changeOrderStatusAllHandler"
            :indeterminate="isAllIndeterminateOrderStatus"
          >
            <span>All</span>
          </a-checkbox>
        </div>
        <div v-for="(value, key) in orderStatuses" :key="key">
          <div v-if="key !== 'canceled'" class="mb-1">
            <a-checkbox v-model="orderStatuses[key]">
              <span>{{ capitalize(key) }}</span>
            </a-checkbox>
          </div>
          <div v-else class="d-flex justify-content-between align-items-center mb-1">
            <a-checkbox v-model="orderStatuses[key]" @change="changeOrderStatusCanceledHandler">
              <span>{{ capitalize(key) }}</span>
            </a-checkbox>
            <div class="d-flex align-items-center ml-2">
              <span class="mr-2">Reason</span>
              <a-switch v-model="isCanceledReason" size="small" :disabled="!orderStatuses.canceled" />
            </div>
          </div>
        </div>
      </div>
    </a-col>
    <a-col :span="24">
      <div class="d-flex flex-grow-1 justify-content-end">
        <a-button type="primary" @click="exportCSVHandler" :loading="isExportCSVLoading">Export</a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { mapGetters } from 'vuex'
import PaymentsService from '../../services/api/payments/payments.service'
import { capitalize, downloadFileByLink } from '../../utils'
import CustomMultiselect from '../../components/multiselect/CustomMultiselect.vue'

export default {
  name: 'exportCSVModal',
  components: { CustomMultiselect },
  emits: ['close'],
  props: {
    projectList: {
      type: Array,
      default: () => [],
    },
    hideProjectList: {
      type: Boolean,
      default: () => false,
    },
    defaultSelectedProjects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      noAgentsFound: [
        { id: 'unaccepted_only', name: 'Not accepted' },
        { id: 'no_agent', name: 'No agent' },
        { id: 'wrong_range', name: 'Wrong range' },
        { id: 'wrong_subrange', name: 'Wrong sub-range' },
      ],
      selectedOrderAgents: {
        regularAgents: [],
        externalAgents: [],
        noAgentsFound: [],
        systemAgents: [],
      },
      selectedProjects: [],
      selectedNoAgentsFound: [],
      selectedRegularAgents: [],
      selectedExternalAgents: [],
      selectedSystemAgents: [],
      useOrderAgentsSelected: {
        regularAgents: {
          value: false,
          disabled: false,
        },
        externalAgents: {
          value: false,
          disabled: false,
        },
        noAgentsFound: {
          value: false,
          disabled: false,
        },
        systemAgents: {
          value: false,
          disabled: false,
        },
      },
      isAllOrderAgents: false,
      isAllOrderStatus: false,
      orderStatuses: {
        confirmed: false,
        onappeal: false,
        rejected: false,
        canceled: false,
        expired: false,
      },
      isCanceledReason: false,
      selectedCurrencyId: null,
      supportedCurrencies: ['RUB', 'KZT'],
      amountFrom: null,
      amountTo: null,
      isExportCSVLoading: false,
    }
  },
  computed: {
    ...mapGetters(['agentList']),
    ...mapGetters(['activeProjectList']),
    ...mapGetters(['currencies']),
    isAllIndeterminateOrderAgents() {
      const values = Object.values(this.useOrderAgentsSelected)
        .filter(agent => !agent.disabled)
        .map(agent => agent.value)

      if (values.length === 0) {
        return false
      }

      if (values.every(value => value === true)) {
        return false
      }

      return values.includes(true) && !values.every(value => value === false)
    },
    isAllIndeterminateOrderStatus() {
      const values = Object.values(this.orderStatuses)

      if (values.every(value => value === true)) {
        return false
      }

      return values.includes(true) && !values.every(value => value === false)
    },
    amountCurrencies() {
      return this.currencies.filter(currency => this.supportedCurrencies.includes(currency.name))
    },
    selectedCurrencyName() {
      const currency = this.currencies.find(currency => currency.id === this.selectedCurrencyId)

      return currency ? currency.name : ''
    },
    regularAgents() {
      return this.agentList?.filter(agent => agent.type === 'regular') ?? []
    },
    externalAgents() {
      return this.agentList?.filter(agent => agent.type === 'external') ?? []
    },
    systemAgents() {
      return this.agentList?.filter(agent => agent.type === 'system') ?? []
    },
  },
  watch: {
    useOrderAgentsSelected: {
      handler(object) {
        const values = Object.values(object)
          .filter(agent => !agent.disabled)
          .map(agent => agent.value)

        if (values.length === 0) {
          return false
        }

        if (values.every(value => value === true)) {
          return false
        }

        this.isAllOrderAgents = values.includes(true) && !values.every(value => value === false)
      },
      deep: true,
    },
    orderStatuses: {
      handler(object) {
        this.isAllOrderStatus = Object.values(object).every(value => value === true)
      },
      deep: true,
    },
  },
  methods: {
    capitalize,
    changeOrderStatusCanceledHandler(e) {
      this.isCanceledReason = e.target.checked
    },
    // Agents start
    selectAllAgents(objectKey) {
      if (this[objectKey] && this[objectKey].length > 0) {
        this.selectedOrderAgents[objectKey] = this[objectKey].map(agent => agent.id)
      }
    },
    unselectAllAgents(objectKey) {
      if (this[objectKey] && this[objectKey].length > 0) {
        this.selectedOrderAgents[objectKey] = []
      }
    },
    changeUseAgentsSelected(event, objectKey) {
      event.target.checked ? this.selectAllAgents(objectKey) : this.unselectAllAgents(objectKey)
    },
    changeAllOrderAgentsHandler(e) {
      const isChecked = e.target.checked

      Object.keys(this.useOrderAgentsSelected).forEach(key => {
        if (!this.useOrderAgentsSelected[key].disabled) {
          this.useOrderAgentsSelected[key].value = isChecked
        }
      })

      if (isChecked) {
        this.selectAllAgents('regularAgents')
        this.selectAllAgents('externalAgents')
        this.selectAllAgents('systemAgents')
        this.selectAllAgents('noAgentsFound')
      } else {
        this.unselectAllAgents('regularAgents')
        this.unselectAllAgents('externalAgents')
        this.unselectAllAgents('systemAgents')
        this.unselectAllAgents('noAgentsFound')
      }
    },
    // Agents end
    // Order status start
    changeOrderStatusAllHandler(e) {
      const isChecked = e.target.checked

      if (isChecked) {
        Object.keys(this.orderStatuses).forEach(key => {
          this.orderStatuses[key] = true
        })

        this.isCanceledReason = true
      } else {
        Object.keys(this.orderStatuses).forEach(key => {
          this.orderStatuses[key] = false
        })

        this.isCanceledReason = false
      }
    },
    // Order status end
    customParser(value) {
      return value
        .replace(/[^\d.-]/g, '')
        .replace(/^(-?)(\d*\.?\d*).*/, '$1$2')
    },
    async exportCSVHandler() {
      try {
        this.isExportCSVLoading = true
        const payload = this.prepareExportPayload()
        const { data } = await PaymentsService.exportPaymentCSVFile(payload)
        downloadFileByLink(data.file_path)
        this.$emit('close')
        this.$notification.success({
          message: 'Data export was successful',
          description: '',
        })
      } catch (error) {
        this.$notification.error({
          message: 'Failed to export data',
          description: '',
        })
      } finally {
        this.isExportCSVLoading = false
      }
    },
    prepareExportPayload() {
      const statuses = Object.keys(this.orderStatuses).filter(key => this.orderStatuses[key])

      const payload = {
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
        with_reason: this.isCanceledReason,
        project_ids: this.selectedProjects.length ? [...this.selectedProjects] : null,
        regular_agents: this.selectedOrderAgents.regularAgents.length ? [...this.selectedOrderAgents.regularAgents] : null,
        external_agents: this.selectedOrderAgents.externalAgents.length ? [...this.selectedOrderAgents.externalAgents] : null,
        system_agents: this.selectedOrderAgents.systemAgents.length ? [...this.selectedOrderAgents.systemAgents] : null,
        order_types: this.selectedOrderAgents.noAgentsFound.length ? [...this.selectedOrderAgents.noAgentsFound] : null,
        statuses: statuses.length ? statuses : null,
        amount_min: this.amountFrom,
        amount_max: this.amountTo,
        currency_id: this.selectedCurrencyId,
      }

      return Object.fromEntries(Object.entries(payload).filter(([_, value]) => value !== null))
    },
  },
  beforeMount() {
    this.selectedCurrencyId = this.amountCurrencies[0].id

    Object.keys(this.useOrderAgentsSelected).forEach(key => {
      this.useOrderAgentsSelected[key].disabled = !this[key].length
    })

    if (this.defaultSelectedProjects.length) {
      this.selectedProjects.push(...this.defaultSelectedProjects.map(p => p.id))
    }
  },
}
</script>
