<template>
  <div>
    <div class="order-recipients-container">
      <div v-if="loading">
        <a-icon type="loading" />
      </div>
      <div v-else class="order-recipient-object">
        <div v-for="obj in data" :key="obj.id" class="p-1">
          <agent-avatar :agent="obj"/>
          {{ obj.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import agentAvatar from '@/components/agent/AgentAvatar.vue'
import PaymentsService from '../../services/api/payments/payments.service'

export default {
  name: 'paymentRecipientsModal',
  components: { agentAvatar },
  props: ['paymentId'],
  data() {
    return {
      data: [],
      loading: false,
    }
  },
  mounted () {
    this.getRecipients()
  },
  methods: {
    async getRecipients() {
      this.loading = true
      return PaymentsService.getClientPaymentRecipients(this.paymentId).then((response) => {
        this.data = response
      }).catch(error => {
        console.log(error)
        this.$message.error('Error while loading order notification recipients')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
