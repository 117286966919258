<script>
const statuses = {
  CREATED: {
    color: 'text-gray-4',
    title: 'Created',
  },
  ACCEPTED: {
    color: 'text-gray-5',
    title: 'Accepted',
  },
  CANCELED: {
    color: 'text-danger',
    title: 'Canceled',
  },
  EXPIRED: {
    color: 'text-danger',
    title: 'Expired',
  },
  CASHED: {
    color: 'text-gray-6',
    title: 'Cashed',
  },
  ONAPPEAL: {
    color: 'text-warning',
    title: 'On appeal',
  },
  CONFIRMED: {
    color: 'text-success',
    title: 'Confirmed',
  },
  COMPLETED: {
    color: 'text-success',
    title: 'Completed',
  },
  ACTIVATED: {
    color: 'text-success',
    title: 'Activated',
  },
  EMPTY: {
    color: 'text-gray-3',
    title: 'Empty',
  },
  REJECTED: {
    color: 'text-warning',
    title: 'Rejected',
  },
  EXTENDED: {
    color: 'text-danger',
    title: 'Extended',
  },
  FAILED: {
    color: 'text-danger',
    title: 'Failed',
  },
  PROCESSING: {
    color: 'text-gray-4',
    title: 'Processing',
  },
  PENDING: {
    color: 'text-orange',
    title: 'Pending',
  },
}

export default {
  name: 'OrderStatus',
  props: {
    title: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'top',
    },
    status: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      default: 'font-size-18',
    },
  },
  data() {
    return {
      statuses,
    }
  },
  computed: {
    currentTypeOrder() {
      return statuses[this.status]
    },
  },
}
</script>

<template>
  <a-tooltip :title="title || currentTypeOrder.title" :overlayStyle="{ whiteSpace: 'pre-line' }" :placement="placement">
    <span :class="[fontSize, currentTypeOrder.color]">
      <font-awesome-icon :icon="status === 'CANCELED' ? 'times-circle' : 'money-check'"/>
    </span>
  </a-tooltip>
</template>
